import { Button, Form, Input, Modal, Radio, Spin } from 'antd';
import { SBSelection } from 'common/components';
import {
    SUCCESSFULLY_CREATION_MESSAGE,
    SUCCESSFULLY_EDIT_MESSAGE,
    TextArea
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createServiceBoat, updateServiceBoat, getOriginalBoatsSelection } from 'redux/thunks';

const ServiceBoatForm = ({ open = false, boat, onClose = () => {}, onReload = () => {} }) => {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const upsertLoading = useSelector((s) => s.serviceBoat.loading || false);
    const { data: originalBoatsSelection, loading: originalBoatsLoading = false } = useSelector(
        (s) => s.originalBoatSelection || {}
    );

    const isUpdateForm = !!boat;

    useEffect(() => {
        if (open) {
            dispatch(getOriginalBoatsSelection());
        }
    }, [open]);

    useEffect(() => {
        if (isUpdateForm) {
            form.setFieldsValue({
                ...boat,
                meoxLoggerId: boat?.meoxLoggers?.find((logger) => logger.isUsed)?.meoxLoggerId
            });
        } else {
            form.resetFields();
        }
    }, [boat]);

    const createOrUpdateBoat = async (req) => {
        if (isUpdateForm) {
            handleUpdateBoat(req);
        } else {
            handleCreateBoat(req);
        }
    };

    const handleCreateBoat = async (req) => {
        try {
            await dispatch(createServiceBoat(req)).unwrap();

            alertSuccessMessage(SUCCESSFULLY_CREATION_MESSAGE);
            form.resetFields();
            onClose();
            onReload(false);
        } catch (err) {
            console.log(err);
            alertErrorMessage(err);
        }
    };

    const handleUpdateBoat = async (req) => {
        if (req.meoxLoggerId) {
            req.meoxLoggers = boat.meoxLoggers.map((logger) => ({
                meoxLoggerId: logger.meoxLoggerId,
                isUsed: logger.meoxLoggerId === req.meoxLoggerId
            }));
        }

        try {
            await dispatch(updateServiceBoat({ id: boat.id, item: req })).unwrap();

            alertSuccessMessage(SUCCESSFULLY_EDIT_MESSAGE);
            onClose();
            onReload(false);
        } catch (err) {
            alertErrorMessage(err);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={
                isUpdateForm
                    ? t('serviceBoat.serviceBoatForm.editServiceBoat')
                    : t('serviceBoat.serviceBoatForm.createServiceBoat')
            }
            open={open}
            onCancel={handleCancel}
            width={580}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className="m-1">
                        {t('button.cancel')}
                    </Button>
                    <Button
                        onClick={form.submit}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {isUpdateForm ? t('button.saveChanges') : t('button.create')}
                    </Button>
                </>
            }
        >
            <Spin spinning={upsertLoading}>
                <Form
                    name="basic"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={createOrUpdateBoat}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item label="id" name="id" hidden={true} required={false}>
                        <Input placeholder="id..." />
                    </Form.Item>

                    <Form.Item
                        name="originalBoatId"
                        label={t('serviceBoat.serviceBoatForm.name.title')}
                        rules={[
                            {
                                required: !isUpdateForm,
                                message: t('serviceBoat.serviceBoatForm.name.required')
                            }
                        ]}
                        hidden={isUpdateForm}
                    >
                        <SBSelection
                            placeholder={t('serviceBoat.serviceBoatForm.name.placeholder')}
                            listSelectItem={originalBoatsSelection}
                            loading={originalBoatsLoading}
                            disabled={isUpdateForm}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('serviceBoat.serviceBoatForm.name.title')}
                        name="name"
                        hidden={!isUpdateForm}
                        required={false}
                    >
                        <Input placeholder={t('serviceBoat.serviceBoatForm.name.title')} disabled />
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label={t('serviceBoat.serviceBoatForm.description.title')}
                    >
                        <TextArea
                            placeholder={t('serviceBoat.serviceBoatForm.description.placeholder')}
                        />
                    </Form.Item>

                    <Form.Item
                        name="externalBoatId"
                        label={t('serviceBoat.serviceBoatForm.externalBoatId.title')}
                        rules={[
                            {
                                required: true,
                                message: t('serviceBoat.serviceBoatForm.externalBoatId.required')
                            }
                        ]}
                    >
                        <Input
                            disabled={isUpdateForm}
                            placeholder={t(
                                'serviceBoat.serviceBoatForm.externalBoatId.placeholder'
                            )}
                        />
                    </Form.Item>

                    {boat?.meoxLoggers?.length > 0 && (
                        <Form.Item
                            name="meoxLoggerId"
                            label={t('serviceBoat.serviceBoatForm.meoxLoggerId.title')}
                            rules={[
                                {
                                    required: true,
                                    message: t('serviceBoat.serviceBoatForm.meoxLoggerId.required')
                                }
                            ]}
                        >
                            <Radio.Group>
                                {boat.meoxLoggers.map((logger) => (
                                    <Radio value={logger.meoxLoggerId}>{logger.meoxLoggerId}</Radio>
                                ))}
                            </Radio.Group>
                        </Form.Item>
                    )}
                </Form>
            </Spin>
        </Modal>
    );
};

ServiceBoatForm.propTypes = {
    open: PropTypes.bool,
    boat: PropTypes.object,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default ServiceBoatForm;
