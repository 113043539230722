import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import EventData from './EventData';
import FilterEvents from './FilterEvents';

const Event = () => {
    const { selectedEventId } = useSelector((s) => s.filter.data);
    const { loading } = useSelector((s) => s.eventDetail);

    return (
        <>
            <FilterEvents />

            {selectedEventId && (
                <div className="event-detail mt-2">
                    <Spin spinning={loading}>
                        <EventData />
                    </Spin>
                </div>
            )}
        </>
    );
};

export default Event;
