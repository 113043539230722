import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SBGeneralInfo } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceBoatDetail } from 'redux/thunks';

const ServiceBoatInfo = () => {
    const { t } = useTranslation();
    const { boatId } = useParams();
    const dispatch = useDispatch();

    const { data: boat = {}, loading = false } = useSelector((s) => s.serviceBoatDetail);

    useEffect(() => {
        dispatch(getServiceBoatDetail(boatId));
    }, [boatId]);

    const infoList = [
        {
            key: t('serviceBoatDetail.farmerCompany'),
            value: boat.companyOwnerName
        },
        {
            key: t('serviceBoatDetail.boatName'),
            value: boat.name
        },
        {
            key: t('serviceBoatDetail.externalBoatId'),
            value: boat.externalBoatId
        },
        {
            key: t('serviceBoatDetail.description'),
            value: boat.description
        }
    ];

    return (
        <SBGeneralInfo
            loading={loading}
            title={t('serviceBoatDetail.title')}
            infoList={infoList}
            logoUrl={boat.companyOwnerLogoUrl}
            serviceBoatId={boatId}
        />
    );
};

export default ServiceBoatInfo;
