import {
    CheckCircleTwoTone,
    DeleteOutlined,
    EditOutlined,
    GlobalOutlined,
    PlusOutlined,
    RestOutlined,
    RollbackOutlined
} from '@ant-design/icons';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import { Button, Col, Row, Space, Spin, Tabs } from 'antd';
import { SBTable } from 'common/components';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_GREEN_CLASS,
    SUCCESSFULLY_DELETION_MESSAGE,
    SUCCESSFULLY_RESTORE_MESSAGE,
    Title
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteServiceBoat,
    getServiceBoatListOnNavbar,
    getServiceBoats,
    restoreServiceBoat
} from 'redux/thunks';
import Swal from 'sweetalert2';
import ServiceBoatForm from './ServiceBoatForm';

const ServiceBoat = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { data: boats = [], loading = false } = useSelector((s) => s.serviceBoat);

    const [isDeleteTab, setIsDeleteTab] = useState(false);
    const [modalState, setModalState] = useState({ open: false, boat: null });

    const columns = [
        {
            dataIndex: 'name',
            className: 'name',
            title: t('serviceBoat.serviceBoatTable.name'),
            sortDirections: ['ascend', 'descend'],
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 1
            },
            render: (_, record) => (
                <div className="avatar-info">
                    <Title level={5}>{record.name}</Title>
                    <p>{record.companyOwner || t('serviceBoat.serviceBoatTable.companyOwner')}</p>
                </div>
            )
        },
        {
            dataIndex: 'mmsi',
            className: 'mmsi',
            title: t('serviceBoat.serviceBoatTable.mmsi')
        },
        {
            dataIndex: 'description',
            className: 'description',
            title: t('serviceBoat.serviceBoatTable.description')
        },
        {
            dataIndex: 'externalBoatId',
            className: 'externalBoatId',
            title: t('serviceBoat.serviceBoatTable.externalBoatId')
        },
        {
            dataIndex: 'meoxLoggers',
            className: 'loggerIds',
            title: t('serviceBoat.serviceBoatTable.meoxLoggers'),
            render: (_, record) =>
                record.meoxLoggers?.map((meoxLogger) => (
                    <p>
                        <span>{meoxLogger.meoxLoggerId}</span>
                        {meoxLogger.isUsed && <CheckCircleTwoTone className="text-lg ml-1" />}
                    </p>
                ))
        },
        {
            dataIndex: 'action',
            className: 'action',
            title: t('serviceBoat.serviceBoatTable.action'),
            render: (_, record) => (
                <Space size="middle">
                    {isDeleteTab ? (
                        <Button
                            className={`restore-boat ${BUTTON_GRAY_CLASS}`}
                            onClick={() => onRestore(record.id)}
                        >
                            <RollbackOutlined />
                            <span>{t('general.action.restore')}</span>
                        </Button>
                    ) : (
                        <>
                            <Button
                                className={`edit-boat ${BUTTON_GRAY_CLASS}`}
                                onClick={() => handleShowModal(record)}
                            >
                                <EditOutlined />
                                <span>{t('general.action.edit')}</span>
                            </Button>
                            <Button
                                className={`delete-boat ${BUTTON_GREEN_CLASS}`}
                                onClick={() => onDelete(record.id)}
                            >
                                <DeleteOutlined />
                                <span>{t('general.action.delete')}</span>
                            </Button>
                        </>
                    )}
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getServiceBoats({ isDeleted: isDeleteTab }));
    }, [isDeleteTab]);

    const handleTabChange = (isDeleted) => setIsDeleteTab(isDeleted);

    const handleReload = (isDeleted) => {
        if (isDeleteTab === isDeleted) dispatch(getServiceBoats({ isDeleted: isDeleted }));
        setIsDeleteTab(isDeleted);
        dispatch(getServiceBoatListOnNavbar());
    };

    const handleClose = () => setModalState({ open: false, boat: null });

    const handleShowModal = (boat) => setModalState({ open: true, boat });

    const onDelete = (id = null) => {
        Swal.fire({
            title: t('general.action.deleteModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0369a1',
            cancelButtonColor: '#64748b',
            confirmButtonText: t('general.action.deleteModalWithoutRevert.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteServiceBoat(id)).unwrap();

                    handleReload(isDeleteTab);
                    alertSuccessMessage(SUCCESSFULLY_DELETION_MESSAGE);
                } catch (err) {
                    alertErrorMessage(err);
                }
            }
        });
    };

    const onRestore = (id = null) => {
        Swal.fire({
            title: t('general.action.restoreModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0369a1',
            cancelButtonColor: '#64748b',
            confirmButtonText: t('general.action.restoreModal.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(restoreServiceBoat(id)).unwrap();

                    handleReload(isDeleteTab);
                    alertSuccessMessage(SUCCESSFULLY_RESTORE_MESSAGE);
                } catch (err) {
                    alertErrorMessage(err);
                }
            }
        });
    };

    return (
        <>
            <Title level={4}>{t('serviceBoat.title')}</Title>
            <Row justify="center">
                <Col span={24}>
                    <Button
                        className={`new-boat ${BUTTON_BLUE_CLASS}`}
                        onClick={() => handleShowModal(null)}
                    >
                        <PlusOutlined />
                        <span>{t('serviceBoat.newServiceBoatBtn')}</span>
                    </Button>

                    <Spin spinning={loading}>
                        <Tabs
                            className="boat-management-tabs"
                            activeKey={isDeleteTab}
                            animated={true}
                            onChange={handleTabChange}
                            items={[
                                {
                                    label: (
                                        <span className="active-boats">
                                            <GlobalOutlined />
                                            <span>{t('serviceBoat.activeServiceBoats')}</span>
                                        </span>
                                    ),
                                    key: false,
                                    children: (
                                        <SBTable
                                            className="boat-management-table"
                                            columns={columns}
                                            data={boats}
                                            totalRecords={boats.length}
                                            rowKey="id"
                                            boats
                                        />
                                    )
                                },
                                {
                                    label: (
                                        <span className="deleted-boats">
                                            <RestOutlined />
                                            <span>{t('serviceBoat.deletedServiceBoats')}</span>
                                        </span>
                                    ),
                                    key: true,
                                    children: (
                                        <SBTable
                                            className="boat-management-deleted-table"
                                            columns={columns}
                                            data={boats}
                                            totalRecords={boats.length}
                                            rowKey="id"
                                        />
                                    )
                                }
                            ]}
                        />
                    </Spin>
                </Col>
            </Row>

            <ServiceBoatForm
                open={modalState.open}
                boat={modalState.boat}
                onClose={handleClose}
                onReload={handleReload}
            />
        </>
    );
};

export default withAITracking(reactPlugin, ServiceBoat);
