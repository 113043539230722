export const FORBIDDEN_PAGE = '/forbidden';
export const SESSION_EXPIRED_PAGE = '/session-expired';
export const UNAUTHORIZED_PAGE = '/unauthorized';
export const ORGANIZATION_PAGE = '/organization';

export const DASHBOARD_PAGE = '/';
export const DAILY_PAGE = '/daily';
export const SERVICE_BOAT_PAGE = '/service-boat';
export const PROFILE_PAGE = '/profile';
export const EVENT_DETAIL_PAGE = '/event';
export const SERVICE_BOAT_DETAIL_PAGE = '/boat-detail';
export const STRESS_DETECTION_PAGE = '/stress-detection';
export const USER_PAGE = '/user';
