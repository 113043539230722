import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const PenList = ({
    open = false,
    pens = [],
    onClose = () => {},
    onStressDetectionStart = () => {}
}) => {
    const { t } = useTranslation();
    const [selectedPen, setSelectedPen] = useState();

    const { loading = false } = useSelector((s) => s.stressDetectionStartStop);

    useEffect(() => {
        setSelectedPen(null);
    }, [pens]);

    const handlePenChange = (pen) => {
        setSelectedPen(pen);
    };

    return (
        <Modal
            className="pen-list-modal"
            title={t('stressDetection.penList.title')}
            open={open}
            onCancel={onClose}
            footer={
                <Button
                    onClick={() => onStressDetectionStart(selectedPen)}
                    disabled={!selectedPen}
                    icon={<CaretRightOutlined className="text-2xl" />}
                    loading={loading}
                    className="bg-sky-700 hover:bg-sky-800 focus-within:bg-sky-700 hover:text-white focus-within:text-white text-white text-lg 
                        font-semibold h-10 px-2 rounded border-none mb-2 w-full flex justify-center items-center start-counting-btn"
                >
                    <span>{t('stressDetection.penList.startDetectStress')}</span>
                </Button>
            }
        >
            <Row gutter={[8, 8]}>
                {pens.length ? (
                    pens.map((pen) => {
                        return (
                            <Col xs={12} sm={8} md={6} lg={6} xl={6} xxl={6} key={pen.id}>
                                <button
                                    onClick={() => handlePenChange(pen)}
                                    className={`h-20 w-full sm:text-xl text-black bg-transparent rounded font-semibold border-solid border-gray-300 hover:bg-slate-600 hover:text-white
                                        cursor-pointer flex justify-center items-center align-middle transition ${
                                            pen.id === selectedPen?.id &&
                                            '!bg-slate-600 !text-white'
                                        }`}
                                    data-testid="pen-option"
                                >
                                    {pen.penNumber}
                                </button>
                            </Col>
                        );
                    })
                ) : (
                    <div className="text-center w-full text-gray-700">
                        {t('stressDetection.penList.noPenIsFound')}
                    </div>
                )}
            </Row>
        </Modal>
    );
};

PenList.propTypes = {
    open: PropTypes.bool,
    pens: PropTypes.array,
    onClose: PropTypes.func,
    onStartDetectStress: PropTypes.func
};

export default PenList;
