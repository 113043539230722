import { createAsyncThunk } from '@reduxjs/toolkit';
import { SETUP_ACCOUNT_API } from 'common/constants';
import { API } from 'services';

export const setupAccount = createAsyncThunk(
    'setupAccount',
    async (filters = {}, { rejectWithValue }) => {
        try {
            const { data } = await API.post(SETUP_ACCOUNT_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
