import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import { Button, Result } from 'antd';
import { BUTTON_BLUE_CLASS } from 'common/constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound = () => {
    const { t } = useTranslation();
    return (
        <Result
            status="404"
            title={t('notFound.title')}
            subTitle={t('notFound.subTitle')}
            extra={
                <div className="flex justify-center">
                    <Link to="/">
                        <Button className={BUTTON_BLUE_CLASS}>Back Home</Button>
                    </Link>
                </div>
            }
        />
    );
};

export default withAITracking(reactPlugin, NotFound);
