import { Button, Modal, Spin, Tree } from 'antd';
import { SUCCESSFULLY_UPDATE_MESSAGE } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, updateUser } from 'redux/thunks';
import './UserForm.scss';

const UserForm = ({ open = false, user, onClose = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const updateLoading = useSelector((s) => s.user.loading || false);
    const { data: boatList } = useSelector((s) => s.serviceBoatList);

    const [serviceBoatIds, setServiceBoatIds] = useState([]);

    useEffect(() => {
        setServiceBoatIds(user?.serviceBoatIds || []);
    }, [user]);

    const handleUserUpdate = async () => {
        try {
            await dispatch(updateUser({ id: user?.id, item: { serviceBoatIds } })).unwrap();

            dispatch(getUsers());
            alertSuccessMessage(SUCCESSFULLY_UPDATE_MESSAGE);
            onClose();
        } catch (err) {
            alertErrorMessage(err);
        }
    };

    return (
        <Modal
            title={t('user.table.assign')}
            open={open}
            onCancel={() => onClose()}
            width={300}
            footer={
                <>
                    <Button type="default" onClick={() => onClose()} className="m-1">
                        {t('button.cancel')}
                    </Button>
                    <Button
                        onClick={handleUserUpdate}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {t('button.saveChanges')}
                    </Button>
                </>
            }
        >
            <Spin spinning={updateLoading}>
                {boatList.length ? (
                    <Tree
                        className="user-tree disabled"
                        checkable={true}
                        onCheck={(selectedKeys) => setServiceBoatIds(selectedKeys)}
                        checkedKeys={serviceBoatIds}
                        treeData={boatList.map((boat) => ({
                            key: boat.id,
                            title: boat.name
                        }))}
                    />
                ) : (
                    <p className="text-center">{t('user.emptyBoat')}</p>
                )}
            </Spin>
        </Modal>
    );
};

export default UserForm;
