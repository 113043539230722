import { AreaChartOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Avatar, Layout as LibLayout, Menu, Popover } from 'antd';
import defaultAvatar from 'assets/Default_Avatar.png';
import CountryPicker from 'common/layouts/Header/CountryPicker';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import i18n from 'translation/i18n';

const Header = () => {
    const { t } = useTranslation();
    const { Header } = LibLayout;
    const { instance } = useMsal();

    const { data: profile } = useSelector((s) => s.profile);

    const handleLogout = () => {
        localStorage.clear();
        instance.logoutRedirect();
    };

    const handleChangeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <Header theme="dark" className="header" style={{ zIndex: 10 }}>
            <div className="flex items-center justify-end gap-7 ">
                <CountryPicker onChangeLanguage={handleChangeLanguage} />

                <Popover
                    className="profile-dropdown"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    content={
                        <Menu mode="inline" className="auth-menu" selectable={false}>
                            <Menu.Item key="1">
                                <NavLink to="/profile">
                                    <UserOutlined />
                                    <span>{t('layout.header.viewProfile')}</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key="2">
                                <NavLink to="/">
                                    <AreaChartOutlined />
                                    <span>{t('layout.header.analysis')}</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key="3" onClick={handleLogout}>
                                <LogoutOutlined />
                                <span>{t('layout.header.logout')}</span>
                            </Menu.Item>
                        </Menu>
                    }
                    placement="bottomRight"
                >
                    <span className="user-name cursor-pointer hidden xl:!inline">
                        {t('layout.header.greeting')} {profile?.name || '...'}
                    </span>
                    <Avatar className="cursor-pointer w-8" src={profile?.avatar || defaultAvatar} />
                </Popover>
            </div>
        </Header>
    );
};

export default Header;
