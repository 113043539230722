import Swal from 'sweetalert2';
import { ERROR_OCCURRED, INTERNAL_SERVER } from 'common/constants';
import i18n from 'translation/i18n';

export const flattenRoutes = (routes) =>
    routes
        .map((route) => [route.children ? flattenRoutes(route.children) : [], route])
        .flat(Infinity)
        .filter(({ name }) => name);

export const alertSuccessMessage = (message) => {
    Swal.fire({
        icon: 'success',
        title: i18n.t(message),
        timer: 5000
    });
};

export const alertErrorMessage = (message) => {
    if (typeof message === 'object') {
        message = message[0]?.errorMessage || ERROR_OCCURRED;
    }

    if (!message) {
        message = INTERNAL_SERVER;
    }

    Swal.fire({
        icon: 'error',
        title: i18n.t(message)
    });
};

export const getAuthority = (tenantId) => {
    return tenantId
        ? `https://login.microsoftonline.com/${tenantId}`
        : 'https://login.microsoftonline.com/organizations';
};
