import { Spin } from 'antd';
import goodEyeImage from 'assets/Good_Eye_Black.png';
import { CROWDING } from 'common/constants';
import { alertErrorMessage } from 'common/utils';
import { useTimer } from 'hooks';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getStressDetectionEngineStatus,
    getStressDetectionFarmerCompaniesSelection,
    getStressDetectionFarmerSitesSelection,
    getStressDetectionServiceBoatsSelection,
    startStressDetection
} from 'redux/thunks';
import { ENGINE_SERVICE } from 'services';
import PenList from './PenList';
import StressDetecting from './StressDetecting';
import './StressDetection.scss';
import StressDetectionForm from './StressDetectionForm';

const StressDetection = () => {
    const dispatch = useDispatch();

    const { timer, updateTimer, handleStartTimer, handleResetTimer } = useTimer();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState({
        farmerCompany: null,
        site: null,
        pen: null,
        serviceBoat: null
    });

    const { data: engineStatus = {}, loading: statusLoading = false } = useSelector(
        (s) => s.stressDetectionStatus
    );
    const { data: serviceBoatsSelection = [] } = useSelector(
        (s) => s.stressDetectionServiceBoatSelection
    );
    const { data: farmerCompaniesSelection = [] } = useSelector(
        (s) => s.stressDetectionFarmerCompanySelection
    );
    const { data: sitesSelection = [] } = useSelector((s) => s.stressDetectionFarmerSiteSelection);

    useEffect(() => {
        dispatch(getStressDetectionServiceBoatsSelection());
        dispatch(getStressDetectionFarmerCompaniesSelection());
        dispatch(getStressDetectionFarmerSitesSelection());

        if (ENGINE_SERVICE.existStressDetectionEngineBaseUrl()) {
            dispatch(getStressDetectionEngineStatus());
        }
    }, []);

    useEffect(() => {
        if (engineStatus.isDetecting) {
            updateTimer(engineStatus.detectingTime || 0);
        } else {
            handleResetTimer();
        }
    }, [engineStatus]);

    useEffect(() => {
        if (!engineStatus.isDetecting) {
            // set selected item to default value if this information is not null
            selectedItem.pen &&
                setSelectedItem({
                    farmerCompany: null,
                    site: null,
                    pen: null,
                    serviceBoat: null
                });

            return;
        }

        // update the information saved in engine to current data if the engine is detecting
        const farmerCompany = farmerCompaniesSelection.find(
            (farmerCompany) => farmerCompany.organizationId === engineStatus.farmerOrganizationId
        );
        const site = sitesSelection.find(
            (site) => site.localityNumber === engineStatus.localityNumber
        );
        const pen = { penNumber: engineStatus.penNumber };
        const serviceBoat = serviceBoatsSelection.find(
            (serviceBoat) => serviceBoat.externalBoatId === engineStatus.externalBoatId
        );

        setSelectedItem({ ...selectedItem, farmerCompany, site, pen, serviceBoat });
    }, [engineStatus, farmerCompaniesSelection, sitesSelection, serviceBoatsSelection]);

    const handleModalClose = () => setIsModalVisible(false);

    const handlePenListShow = () => setIsModalVisible(true);

    const handleStressDetectionStart = async (pen) => {
        try {
            await dispatch(
                startStressDetection({
                    penNumber: pen.penNumber,
                    localityNumber: selectedItem.site?.localityNumber,
                    farmerOrganizationId: selectedItem.farmerCompany?.organizationId,
                    eventType: CROWDING
                })
            ).unwrap();

            setSelectedItem({ ...selectedItem, pen });
            handleStartTimer();
            handleModalClose();
        } catch (error) {
            alertErrorMessage(error);
        }
    };

    return (
        <div className="stress-detection">
            <div className="goodeye-logo flex justify-center my-4">
                <img src={goodEyeImage} alt="" className="xs:w-3/4 sm:w-2/3 lg:w-1/2" />
            </div>
            {statusLoading ? (
                <div className="text-center">
                    <Spin size="large"></Spin>
                </div>
            ) : selectedItem.pen ? (
                // selectedItem.pen means the engine is detecting stress in that pen
                <StressDetecting
                    timer={timer}
                    selectedItem={selectedItem}
                    onResetTimer={handleResetTimer}
                    setSelectedItem={setSelectedItem}
                />
            ) : (
                <>
                    <StressDetectionForm
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        onPenListShow={handlePenListShow}
                    />

                    <PenList
                        open={isModalVisible}
                        pens={selectedItem.site?.pens}
                        onClose={handleModalClose}
                        onStressDetectionStart={handleStressDetectionStart}
                    />
                </>
            )}
        </div>
    );
};

export default StressDetection;
