import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest, msalInstance } from 'authConfig';
import { getAuthority } from './../common/utils/general';

export const getToken = async () => {
    const account = msalInstance.getAllAccounts()[0];
    if (!account) return;

    const request = {
        account: account,
        scopes: loginRequest.scopes,
        authority: getAuthority(account.tenantId)
    };

    try {
        const response = await msalInstance.acquireTokenSilent(request);
        return response.idToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            return msalInstance.acquireTokenRedirect(request);
        }
    }
};
