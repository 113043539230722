import { CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { SBDialogBox } from 'common/components';
import { CROWDING } from 'common/constants';
import { alertErrorMessage, formatSeconds } from 'common/utils';
import { useCallbackPrompt } from 'hooks';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { stopStressDetection } from 'redux/thunks';
import Swal from 'sweetalert2';

const StressDetecting = ({
    timer = 0,
    selectedItem = {},
    onResetTimer = () => {},
    setSelectedItem = () => {}
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
    const { loading = false } = useSelector((s) => s.stressDetectionStartStop);

    const handleStressDetectionStop = (pen) => {
        Swal.fire({
            title: t('stressDetection.stressDetecting.confirmStopMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0369a1',
            cancelButtonColor: '#64748b',
            confirmButtonText: t('button.yes'),
            cancelButtonText: t('button.cancel')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(
                        stopStressDetection({
                            penNumber: pen.penNumber,
                            localityNumber: selectedItem.site?.localityNumber,
                            farmerOrganizationId: selectedItem.farmerCompany?.organizationId,
                            eventType: CROWDING
                        })
                    ).unwrap();

                    setSelectedItem({ ...selectedItem, pen: null });
                    onResetTimer();
                } catch (error) {
                    alertErrorMessage(error);
                }
            }
        });
    };

    return (
        <div className="flex flex-col justify-center items-center">
            <div>
                <p className="text-xl font-semibold text-center my-2">
                    {`${t('stressDetection.stressDetecting.startStressDetectionForPen')} ${
                        selectedItem.pen?.penNumber
                    } ${t('stressDetection.stressDetecting.ofSite')} ${selectedItem.site?.text}`}
                </p>
                <p className="text-2xl font-semibold text-center my-2">{formatSeconds(timer)}</p>
            </div>

            <Button
                type="button"
                onClick={() => handleStressDetectionStop(selectedItem.pen)}
                icon={<CloseCircleOutlined className="text-2xl" />}
                loading={loading}
                className="bg-sky-700 focus-within:bg-sky-700 text-white hover:bg-sky-800 hover:text-white focus-within:text-white text-xl font-semibold 
                    py-6 px-6 rounded border mt-4 flex justify-center items-center stop-counting-btn"
            >
                <span>{t('stressDetection.stressDetecting.stopAndSaveData')}</span>
            </Button>

            {/* This component will show the confirm message when user try to leave this page*/}
            <SBDialogBox
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
                message={'stressDetection.stressDetecting.confirmExitMessage'}
            />
        </div>
    );
};

StressDetecting.propTypes = {
    timer: PropTypes.number,
    selectedItem: PropTypes.object,
    onResetTimer: PropTypes.func,
    setSelectedItem: PropTypes.func
};

export default StressDetecting;
