import { Button, Form } from 'antd';
import { ENGINE_BY_ENGINE_ID, STRESS_DETECTION_ENGINE_BASE_URL_KEY } from 'common/constants';
import { alertErrorMessage } from 'common/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ENGINE_SERVICE, GLOBAL_API } from 'services';
import PropTypes from 'prop-types';
import { SBSelection } from 'common/components';
import { SelectOutlined } from '@ant-design/icons';

const StressDetectionForm = ({
    selectedItem,
    setSelectedItem = () => {},
    onPenListShow = () => {}
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const { data: serviceBoatsSelection = [], loading: serviceBoatLoading = false } = useSelector(
        (s) => s.stressDetectionServiceBoatSelection
    );
    const { data: farmerCompaniesSelection = [], loading: farmerCompanyLoading = false } =
        useSelector((s) => s.stressDetectionFarmerCompanySelection);
    const { data: sitesSelection = [], loading: siteLoading = false } = useSelector(
        (s) => s.stressDetectionFarmerSiteSelection
    );

    const [filteredSitesSelection, setFilteredSitesSelection] = useState([]);
    const [choosePenButtonState, setChoosePenButtonState] = useState({
        disabled: true,
        loading: false
    });

    useEffect(() => {
        setFilteredSitesSelection(sitesSelection);
    }, [sitesSelection]);

    useEffect(() => {
        // update the base url when the selected service boat is changed
        updateStressDetectionEngineBaseUrl(selectedItem.serviceBoat);
    }, [selectedItem.serviceBoat]);

    useEffect(() => {
        if (!selectedItem.farmerCompany) {
            setFilteredSitesSelection(sitesSelection);
            return;
        }

        const newSitesSelection = sitesSelection.filter(
            (site) => site.organizationId === selectedItem.farmerCompany.organizationId
        );
        setFilteredSitesSelection(newSitesSelection);

        // check if the current selected site is in the new sites selection
        // if not we reset site selection in form and selected site to null
        if (
            selectedItem.site &&
            !newSitesSelection.find((site) => site.id === selectedItem.site?.id)
        ) {
            form.setFieldValue('siteId', null);
            setSelectedItem({ ...selectedItem, site: null });
        }
    }, [selectedItem.farmerCompany]);

    const updateStressDetectionEngineBaseUrl = async (serviceBoat) => {
        if (!serviceBoat?.engineId) {
            return;
        }

        try {
            setChoosePenButtonState({ disabled: true, loading: true });
            const res = await GLOBAL_API.get(`${ENGINE_BY_ENGINE_ID}/${serviceBoat.engineId}`);
            const configuration = JSON.parse(res.data.configurationJson);
            const engineBaseUrl = configuration[STRESS_DETECTION_ENGINE_BASE_URL_KEY];

            if (engineBaseUrl) {
                ENGINE_SERVICE.saveStressDetectionEngineBaseUrl(engineBaseUrl);
                setChoosePenButtonState({ disabled: false, loading: false });
            } else {
                ENGINE_SERVICE.removeStressDetectionEngineBaseUrl();
                alertErrorMessage('message.engineNotConfiguredBaseUrl');
                setChoosePenButtonState({ disabled: true, loading: false });
            }
        } catch (error) {
            alertErrorMessage(error);
            setChoosePenButtonState({ disabled: true, loading: false });
            ENGINE_SERVICE.removeStressDetectionEngineBaseUrl();
        }
    };

    const handleFarmerCompanyChange = (companyId) => {
        const farmerCompany = farmerCompaniesSelection.find((company) => company.id === companyId);
        setSelectedItem({ ...selectedItem, farmerCompany });
    };

    const handleSiteChange = (siteId) => {
        const site = sitesSelection.find((site) => site.id === siteId);
        setSelectedItem({ ...selectedItem, site });
    };

    const handleServiceBoatChange = (serviceBoatId) => {
        const serviceBoat = serviceBoatsSelection.find(
            (serviceBoat) => serviceBoat.id === serviceBoatId
        );
        setSelectedItem({ ...selectedItem, serviceBoat });
    };

    return (
        <div className="site-selection title" data-testid="counting-form">
            <div className="text-center text-gray-700 font-semibold text-lg">
                {t('stressDetection.form.title')}
            </div>

            <Form
                initialValues={{
                    farmerOrganizationId: selectedItem.farmerCompany?.id,
                    siteId: selectedItem.site?.id,
                    serviceBoatId: selectedItem.serviceBoat?.id
                }}
                onFinish={onPenListShow}
                className="flex justify-center items-center flex-col py-4"
                form={form}
                layout="vertical"
            >
                <div className="flex xs:flex-col lg:flex-row lg:space-x-4">
                    <Form.Item
                        label={t('stressDetection.form.farmerCompany.label')}
                        name="farmerOrganizationId"
                        className="w-60 font-semibold"
                    >
                        <SBSelection
                            listSelectItem={farmerCompaniesSelection}
                            placeholder={t('stressDetection.form.farmerCompany.placeholder')}
                            className="w-full text-base"
                            onChange={handleFarmerCompanyChange}
                            showSearch={true}
                            allowClear={true}
                            dataTestId="site-option"
                            loading={farmerCompanyLoading}
                            value={selectedItem.farmerCompany?.id}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('stressDetection.form.site.label')}
                        name="siteId"
                        rules={[
                            {
                                required: true,
                                message: t('stressDetection.form.site.required')
                            }
                        ]}
                        className="w-60 font-semibold"
                    >
                        <SBSelection
                            listSelectItem={filteredSitesSelection}
                            placeholder={t('stressDetection.form.site.placeholder')}
                            className="w-full text-base"
                            onChange={handleSiteChange}
                            showSearch={true}
                            dataTestId="site-option"
                            loading={siteLoading}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('stressDetection.form.serviceBoat.label')}
                        name="serviceBoatId"
                        rules={[
                            {
                                required: true,
                                message: t('stressDetection.form.serviceBoat.required')
                            }
                        ]}
                        className="w-60 font-semibold"
                    >
                        <SBSelection
                            listSelectItem={serviceBoatsSelection.map((boat) => ({
                                ...boat,
                                text: boat.hasEngineConfig
                                    ? boat.text
                                    : `${boat.text} ${t(
                                          'stressDetection.form.serviceBoat.engineNotConfigured'
                                      )}`,
                                disabled: !boat.hasEngineConfig
                            }))}
                            placeholder={t('stressDetection.form.serviceBoat.placeholder')}
                            className="w-full text-base"
                            onChange={handleServiceBoatChange}
                            showSearch={true}
                            dataTestId="site-option"
                            loading={serviceBoatLoading}
                        />
                    </Form.Item>
                </div>

                <div className="start-counting flex flex-col justify-center items-center py-4">
                    <Button
                        onClick={() => form.submit()}
                        className="bg-sky-700 focus-within:bg-sky-700 text-white hover:bg-sky-800 hover:text-white focus-within:text-white text-xl font-semibold 
                            py-6 px-6 rounded border flex justify-center items-center show-modal-btn"
                        disabled={choosePenButtonState.disabled}
                        loading={choosePenButtonState.loading}
                        icon={<SelectOutlined />}
                    >
                        {t('stressDetection.form.choosePen')}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

StressDetectionForm.propTypes = {
    selectedSite: PropTypes.object,
    setSelectedSite: PropTypes.func,
    onPenListShow: PropTypes.func
};

export default StressDetectionForm;
