import { EditOutlined } from '@ant-design/icons';
import { Avatar, Button, Space } from 'antd';
import Search from 'antd/es/input/Search';
import defaultAvatar from 'assets/Default_Avatar.png';
import { SBTable } from 'common/components';
import { BUTTON_GRAY_CLASS, Title, YELLOW } from 'common/constants';
import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from 'redux/thunks';
import UserForm from './UserForm';

const User = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { data: users, loading } = useSelector((s) => s.user);

    const [searchText, setSearchText] = useState('');
    const [modalState, setModalState] = useState({ open: false, user: null });

    const columns = [
        {
            title: t('user.table.name'),
            dataIndex: 'name',
            key: 'name',
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 1
            },
            sortDirections: ['ascend', 'descend'],
            onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
            render: (_, record) => (
                <Avatar.Group>
                    <Avatar
                        className="shape-avatar"
                        src={record.avatar || defaultAvatar}
                        size={55}
                        alt=""
                    />
                    <div className="avatar-info ml-2">
                        <Title level={5}>
                            <Highlighter
                                highlightStyle={{
                                    backgroundColor: YELLOW,
                                    padding: 0
                                }}
                                searchWords={[searchText]}
                                autoEscape
                                textToHighlight={record.name}
                            />
                        </Title>
                        <p>{record.email}</p>
                    </div>
                </Avatar.Group>
            )
        },
        {
            title: t('user.table.phoneNumber'),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber'
        },
        {
            title: t('user.table.address'),
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: t('user.table.assignedServiceBoat'),
            dataIndex: 'assignedServiceBoat',
            render: (_, record) =>
                record.serviceBoatNames?.map((boat, index) => <p key={index}>{boat}</p>)
        },
        {
            title: t('user.table.action'),
            dataIndex: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    <Button className={BUTTON_GRAY_CLASS} onClick={() => handleShowModal(record)}>
                        <EditOutlined />
                        <span>{t('user.table.assign')}</span>
                    </Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getUsers());
    }, []);

    const handleClose = () => setModalState({ open: false, user: null });

    const handleShowModal = (user) => setModalState({ open: true, user });

    return (
        <div className="user">
            <h2 className="mb-2">{t('user.title')}</h2>

            <div>
                <div className="mb-1 w-60">
                    <Search
                        placeholder={t('user.searchPlaceholder')}
                        onSearch={(searchText) => setSearchText(searchText.toLowerCase().trim())}
                    />
                </div>
                <SBTable
                    columns={columns}
                    data={users.filter(({ name }) =>
                        name.toLowerCase().trim().includes(searchText)
                    )}
                    loading={loading}
                    totalRecords={users.length}
                    rowKey="id"
                />
            </div>

            <UserForm open={modalState.open} user={modalState.user} onClose={handleClose} />
        </div>
    );
};

export default User;
