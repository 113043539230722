import { Avatar, Button, Drawer, Spin } from 'antd';
import defaultLogo from 'assets/Empty_Box.png';
import goodEyeLogo from 'assets/Good_Eye_Black.png';
import { Title } from 'common/constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './ServicesDrawer.scss';

const ServicesDrawer = () => {
    const goodeyeMenuUrl = process.env.REACT_APP_GOODEYE_MENU_URL;
    const { t } = useTranslation();

    const { data: profile, loading = false } = useSelector((s) => s.profile);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <>
            <Button
                className="googeye-button p-2 my-2 bg-white hover:bg-slate-50 transition-all rounded flex border-2 border-gray-400 focus-within:border-gray-400"
                onClick={() => setIsDrawerOpen(true)}
            >
                <img src={goodEyeLogo} alt="Googeye logo" className="w-auto h-4" />
            </Button>
            <Drawer
                title={
                    <Link to={goodeyeMenuUrl} target="_blank">
                        <img
                            src={goodEyeLogo}
                            alt="Goodeye logo"
                            className="h-8 hover:pl-2 transition-all"
                        />
                    </Link>
                }
                placement="right"
                onClose={() => setIsDrawerOpen(false)}
                open={isDrawerOpen}
                className="service-drawer"
            >
                <Spin spinning={loading}>
                    {!(loading || profile.services?.length) && (
                        <p className="text-center text-base">
                            {t('layout.serviceDrawer.noServices')}
                        </p>
                    )}

                    {profile.services?.map((service) => {
                        return (
                            <Link
                                to={service.url}
                                target="_blank"
                                className="service-item h-20 flex items-center cursor-pointer"
                                key={service.id}
                                data-testid="service-url"
                            >
                                <Avatar.Group>
                                    <img
                                        className="object-contain rounded h-14 w-20"
                                        src={service.logo || defaultLogo}
                                        data-testid="service-logo"
                                        alt="Service logo"
                                    />
                                    <div className="avatar-info ml-2">
                                        <Title level={5}>{service.name}</Title>
                                        <p className="m-0">
                                            {service.companyOwner || 'Service owner'}
                                        </p>
                                    </div>
                                </Avatar.Group>
                            </Link>
                        );
                    })}
                </Spin>
            </Drawer>
        </>
    );
};

export default ServicesDrawer;
