import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import { useParams } from 'react-router-dom';
import ServiceBoatInfo from './ServiceBoatInfo';
import { SBEventChart } from 'components';

const ServiceBoatDetail = () => {
    const { boatId } = useParams();

    return (
        <div className="dashboard">
            <ServiceBoatInfo />
            <SBEventChart boatId={boatId} className="mt-5" />
        </div>
    );
};

export default withAITracking(reactPlugin, ServiceBoatDetail);
