import { createAsyncThunk } from '@reduxjs/toolkit';
import { AVAILABLE_TENANT_API } from 'common/constants';
import { GLOBAL_API } from 'services';

export const getAvailableTenants = createAsyncThunk(
    'tenants/getAvailableTenants',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await GLOBAL_API.get(AVAILABLE_TENANT_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
