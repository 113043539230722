import { createSlice } from '@reduxjs/toolkit';
import { setupAccount } from 'redux/thunks';

const initialState = {
    data: {},
    loading: false
};

export const setupAccountSlice = createSlice({
    name: 'setupAccount',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setupAccount.pending, (state) => {
                state.loading = true;
            })
            .addCase(setupAccount.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(setupAccount.rejected, (state) => {
                state = initialState;
            });
    }
});
