// api endpoint for service boat
export const SERVICE_BOAT_API = 'api/service-boats';
export const SERVICE_BOAT_LIST_API = 'api/service-boats/list';

// api endpoint for selection
export const ORIGINAL_BOATS_SELECTION_API = 'api/selection/original-boats';
export const ENGINE_SERVICE_BOAT_SELECTION_API = 'api/selection/engine-service-boats';
export const SERVICE_BOAT_SELECTION_API = 'api/selection/service-boats';

export const FARMER_COMPANIES_SELECTION_API = 'api/public/service-boat/selection/farmer-companies';
export const FARMER_SITES_SELECTION_API = 'api/public/service-boat/selection/farmer-sites';
export const FARMER_PENS_SELECTION_API = 'api/public/service-boat/selection/farmer-pens';

// api endpoint for profile
export const PROFILE_API = 'api/me/profile';

// api endpoint for daily report
export const DAILY_REPORT_EXPORT_API = 'api/exports/daily';
export const DAILY_REPORT_API = 'api/reports/daily';
export const DAILY_COUNT_API = 'api/reports/daily-count';

// api for event chart report
export const EVENT_CHART_API = 'api/reports/event-chart';

// api endpoint for event
export const EVENT_LIST_API = 'api/events/list';
export const EVENT_DETAIL_API = 'api/events';

// api end point for stress detection
export const STRESS_DETECTION_SERVICE_BOAT_SELECTION_API =
    'api/public/stress-detection/selection/service-boats';
export const STRESS_DETECTION_FARMER_COMPANY_SELECTION_API =
    'api/public/stress-detection/selection/farmer-companies';
export const STRESS_DETECTION_FARMER_SITE_SELECTION_API =
    'api/public/stress-detection/selection/farmer-sites';

export const STRESS_DETECTION_ENGINE_START_API = 'startstop/start';
export const STRESS_DETECTION_ENGINE_STOP_API = 'startstop/stop';

export const STRESS_DETECTION_ENGINE_STATUS_API = 'cstatus';

// api endpoint get engine by locality number
export const ENGINE_BY_ENGINE_ID = 'api/public/engine-by-engine-id';

// api endpoint for setup account
export const SETUP_ACCOUNT_API = 'api/app/setup-account';

// api endpoint for user
export const USER_API = 'api/users';

// api public
export const AVAILABLE_TENANT_API = 'api/public/available-tenants';
