import { AbilityContext } from 'casl';
import { useContext } from 'react';

const useAppAbility = () => {
    const ability = useContext(AbilityContext);
    return {
        can: (action = '', subject = [], conditions = []) => {
            if (!action) return false;

            if (typeof conditions === 'string') return ability.can(action, subject, conditions);

            return (conditions || []).map((cd) => ability.can(action, subject, cd)).some((s) => s);
        }
    };
};

export default useAppAbility;
