import axios from 'axios';
import { BASE_GLOBAL_API_URL, TIME_ZONE } from 'common/constants';
import qs from 'qs';
import { getToken } from './Auth';
import { appInsights } from 'AppInsights';

const instance = axios.create({
    timeout: 100000,
    maxContentLength: 10000
});

const defaultOptions = {
    headers: {
        accept: 'application/json',
        contentType: 'application/json',
        'Time-Zone': TIME_ZONE,
        Authorization: ''
    }
};

const fileExportOptions = {
    headers: {
        'Content-Type': 'blob',
        'Time-Zone': TIME_ZONE,
        Authorization: ''
    },
    responseType: 'arraybuffer'
};

const _get = (url, params = {}, options = {}) => {
    return instance.get(BASE_GLOBAL_API_URL + url, {
        ...defaultOptions,
        ...options,
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
};

const _export = (url, params = {}, options = {}) => {
    return instance.get(BASE_GLOBAL_API_URL + url, {
        ...fileExportOptions,
        ...options,
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
};

const post = (url, body = {}, options = {}) =>
    instance.post(BASE_GLOBAL_API_URL + url, body, { ...defaultOptions, ...options });
const put = (url, body = {}, options = {}) =>
    instance.put(BASE_GLOBAL_API_URL + url, body, { ...defaultOptions, ...options });
const patch = (url, body = {}, options = {}) =>
    instance.patch(BASE_GLOBAL_API_URL + url, body, { ...defaultOptions, ...options });
const _delete = (url, options = {}) =>
    instance.delete(BASE_GLOBAL_API_URL + url, { ...defaultOptions, ...options });

const interceptorHandleRequest = async (config) => {
    const token = await getToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
};

const interceptorHandleResponse = (response) => response;
const handleError = (error) => {
    appInsights.trackException({ exception: error });
    return Promise.reject(error.response.data.errors || error.response.data.message);
};

instance.interceptors.request.use(interceptorHandleRequest, handleError);
instance.interceptors.response.use(interceptorHandleResponse, handleError);

export { _delete as delete, _export as export, _get as get, patch, post, put };
