import { Menu, Popover } from 'antd';
import noFlag from 'assets/flags/No.svg';
import usaFlag from 'assets/flags/Usa.svg';
import { useTranslation } from 'react-i18next';
import './CountryPicker.scss';

const CountryPicker = () => {
    const { i18n } = useTranslation();

    const handleChangeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <Popover
            getPopupContainer={(trigger) => trigger.parentElement}
            content={
                <Menu mode="inline" className="border-none" selectable={false}>
                    <Menu.Item
                        key="en"
                        onClick={() => handleChangeLanguage('en')}
                        className="hover:bg-gray-300 flag-picker-item"
                    >
                        <img
                            className="country-picker-flag w-8 h-6 cursor-pointer object-cover mr-1"
                            src={usaFlag}
                            alt="Flag"
                        />
                        English
                    </Menu.Item>
                    <Menu.Item
                        key="no"
                        onClick={() => handleChangeLanguage('no')}
                        className="hover:bg-gray-300 flag-picker-item"
                    >
                        <img
                            className="country-picker-flag w-8 h-6 cursor-pointer object-cover mr-1"
                            src={noFlag}
                            alt="Flag"
                        />
                        Norwegian
                    </Menu.Item>
                </Menu>
            }
            placement="bottomRight"
        >
            <div>
                {i18n?.language === 'no' ? (
                    <img
                        className="country-picker-flag w-8 h-6 cursor-pointer object-cover"
                        src={noFlag}
                        alt="Flag"
                    />
                ) : (
                    <img
                        className="country-picker-flag w-8 h-6 cursor-pointer object-cover"
                        src={usaFlag}
                        alt="Flag"
                    />
                )}
            </div>
        </Popover>
    );
};

export default CountryPicker;
